<template>
	<div class="all">
		<!-- 抬头标题 -->
		<div class="title-hang">
			<div class="fl-t">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<div class="zhuangshizb fl-t"></div>
			<div class="zhuangshizj fl-t">{{projectData.project}}_运行考核</div>
			<div class="fr-t">
				<div class="fl-t">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
				</div>
			</div>
		</div>
		<!-- 主要内容 -->
		<div class="flex" style="margin-top:3vh;">
			<div class="left">
				<!-- 标题 -->
				<div class="title">
					<div class="left_title">数据显示</div>
				</div>
				<!-- 内容 -->
				<div class="left_content">
					<div class="flex">
						<div class="left_line0">序号&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</div>
						<div class="left_line2">班运行</div>
						<div class="left_line3">天运行</div>
						<div class="left_line4">月运行</div>
						<div class="left_line5">自定义运行</div>
					</div>
					<div class="flex" v-for="(item, index) in list" :key="index">
						<div class="left_line1">{{item.name}}</div>
						<div class="left_line2" style="cursor: pointer;"
							@click="toCompon(2,item.job,publicMark,item.job + 'VV1')"
							@dblclick="Cclick(infoList[publicMark][item.job],item.job,publicMark,item.job + 'VV1')">
							{{infoList[publicMark][item.job]}}
						</div>
						<div class="left_line3" style="cursor: pointer;"
							@click="toCompon(2,item.day,publicMark,item.day + 'VV1')"
							@dblclick="Cclick(infoList[publicMark][item.day],item.day,publicMark,item.day + 'VV1')">
							{{infoList[publicMark][item.day]}}
						</div>
						<div class="left_line4" style="cursor: pointer;"
							@click="toCompon(2,item.month,publicMark,item.month + 'VV1')"
							@dblclick="Cclick(infoList[publicMark][item.month],item.month,publicMark,item.month + 'VV1')">
							{{infoList[publicMark][item.month]}}
						</div>
						<div class="left_line5" style="cursor: pointer;"
							@click="toCompon(2,item.zdy,publicMark,item.zdy+'VV1')"
							@dblclick="Cclick(infoList[publicMark][item.zdy],item.zdy,publicMark,item.zdy+'VV1')">
							{{infoList[publicMark][item.zdy]}}
						</div>
					</div>
				</div>
			</div>
			<div class="middle">
				<div class="middle_up">
					<div class="status">
						<div class="flex justify-content-cen">
							<div class="status_style red" :style="[
								{background:(infoList.MCSJNKH.KHZZKH ?'#0085eb40':'#bb1d36')},
								{color:(infoList.MCSJNKH.KHZZKH ?'#1bb1ff':'#fff')},
								{border:(infoList.MCSJNKH.KHZZKH ?'1px solid #0085eb':'')}
								]">
								{{infoList.MCSJNKH.KHZZKH ? '正在考核' : '等待考核'}}
							</div>
							<div class="status_style blue"
								@click="toIpt(infoList.MCSJNKH.JNKHKS,'按钮','JNKHKS','MCSJNKH')">
								{{infoList.MCSJNKH.JNKHKS ? '结束考核' : '开始考核'}}
							</div>
						</div>
					</div>
					<div class="middle_up_times">
						<div class="flex">
							<div class="box up_box">
								<div class="box_words">考核时间设定</div>
								<div class="box_time"
									@click="toIpt(infoList.MCSJNKH.KH_SJ,'考核时间设定','KH_SJ','MCSJNKH'),toCompon(2,'KH_SJ','MCSJNKH','KH_SJVV1','考核时间')">
									{{infoList.MCSJNKH.KH_SJ}}
								</div>
							</div>
							<div class="box up_box">
								<div class="box_words">考核开始时间</div>
								<div class="box_time">08:00:00</div>
							</div>
						</div>
						<div class="flex">
							<div class="box down_box">
								<div class="box_words">考核进行时间</div>
								<div class="box_time">
									{{infoList.MCSJNKH.KH_JXSJ}}
								</div>
							</div>
							<div class="box down_box">
								<div class="box_words">考核结束时间</div>
								<div class="box_time">2022/2/12 10:00:00</div>
							</div>
						</div>
					</div>

				</div>
				<div class="middle_down">
					<div class="title">
						<div class="left_title">指标名称和精度</div>
					</div>
					<div class="middle_down_content">
						<div class="precision">
							<div class="precision_line flex">
								<div class="precision_box" v-for="(item, index) in list1" :key="index">
									<div class="precision_num"
										@click="toIpt(infoList[publicMark1][item.mark],item.name,item.mark,publicMark1)">
										{{infoList[publicMark1][item.mark]}}
									</div>
									<div class="precision_name">{{item.name}}</div>
									<div class="switchdiv"
										@click="toIpt(infoList[publicMark1][item.swich], item.name,item.swich,publicMark1)"
										v-if="item.swich">
										<el-switch v-model="infoList[publicMark1][item.swich]" active-color="#29DFAE"
											inactive-color="red" disabled>
										</el-switch>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="right_up">

					<div class="title">
						<div class="left_title">总自控率%</div>
					</div>

					<div class="right_up_content flex">
						<div class="content_left">
							<div class="content_left_line flex" v-for="(item, index) in list2" :key="index">
								<div class="words">{{item.name}}</div>
								<div class="num" @click="toCompon(2,item.mark,publicMark2,item.mark + 'VV1')"
									@dblclick="Cclick(infoList[publicMark2][item.mark] || '0.00',item.mark,publicMark2,item.mark + 'VV1')">
									{{infoList[publicMark2][item.mark] || '0.00'}}
								</div>
							</div>
						</div>
						<div class="content_right">
							<div>
								<el-progress type="circle" :percentage="0" :show-text="false"></el-progress>
							</div>
							<div class="control_rate">
								<div class="control_rate_num" @click="toCompon(2,'ZZKL',publicMark2,'ZZKLVV1')"
									@dblclick="Cclick(infoList[publicMark2].ZZKL,'ZZKL',publicMark2,'ZZKLVV1')">
									{{infoList[publicMark2].ZZKL}}
								</div>
								<div class="control_rate_words">总自控率</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right_middle">
					<div class="title">
						<div class="left_title">交班时间</div>
					</div>

					<div class="right_middle_content">
						<div class="nail flex">
							<div class="nail_line nail_line1">
								<div class="nail_word">甲</div>
								<div class="nail_time flex">
									<div class="first_column" style="cursor: pointer;"
										@click="toIpt(parseInt(infoList.MCSTIMESET.BQL_H1),'时','BQL_H1','MCSTIMESET')">
										{{parseInt(infoList.MCSTIMESET.BQL_H1)}}
									</div>
									<div>时</div>
									<div style="cursor: pointer;"
										@click="toIpt(parseInt(infoList.MCSTIMESET.BQL_M1),'分','BQL_M1','MCSTIMESET')">
										{{parseInt(infoList.MCSTIMESET.BQL_M1)}}
									</div>
									<div>分</div>
								</div>
							</div>
							<div class="nail_line">
								<div class="nail_word">乙</div>
								<div class="nail_time flex">
									<div class="first_column" style="cursor: pointer;"
										@click="toIpt(parseInt(infoList.MCSTIMESET.BQL_H2),'时','BQL_H2','MCSTIMESET')">
										{{parseInt(infoList.MCSTIMESET.BQL_H2)}}
									</div>
									<div>时</div>
									<div style="cursor: pointer;"
										@click="toIpt(parseInt(infoList.MCSTIMESET.BQL_M2),'分','BQL_M2','MCSTIMESET')">
										{{parseInt(infoList.MCSTIMESET.BQL_M2)}}
									</div>
									<div>分</div>
								</div>
							</div>
							<div class="nail_line">
								<div class="nail_word">丙</div>
								<div class="nail_time flex">
									<div class="first_column" style="cursor: pointer;"
										@click="toIpt(parseInt(infoList.MCSTIMESET.BQL_H3),'时','BQL_H3','MCSTIMESET')">
										{{parseInt(infoList.MCSTIMESET.BQL_H3)}}
									</div>
									<div>时</div>
									<div style="cursor: pointer;"
										@click="toIpt(parseInt(infoList.MCSTIMESET.BQL_M3),'分','BQL_M3','MCSTIMESET')">
										{{parseInt(infoList.MCSTIMESET.BQL_M3)}}
									</div>
									<div>分</div>
								</div>
							</div>
							<div class="nail_line">
								<div class="nail_word">丁</div>
								<div class="nail_time flex">
									<div class="first_column" style="cursor: pointer;"
										@click="toIpt(parseInt(infoList.MCSTIMESET.BQL_H3),'时','BQL_H4','MCSTIMESET')">
										{{parseInt(infoList.MCSTIMESET.BQL_H4)}}
									</div>
									<div>时</div>
									<div style="cursor: pointer;"
										@click="toIpt(parseInt(infoList.MCSTIMESET.BQL_M4),'分','BQL_M4','MCSTIMESET')">
										{{parseInt(infoList.MCSTIMESET.BQL_M4)}}
									</div>
									<div>分</div>
								</div>
							</div>
						</div>
						<div class="time_record flex">
							<div class="day flex">
								<div class="day_words">天记录时间：</div>
								<div class="day_record flex">
									<div class="first_column" style="cursor: pointer;"
										@click="toIpt(parseInt(infoList.MCSTIMESET.RQL_H1),'时','RQL_H1','MCSTIMESET')">
										{{parseInt(infoList.MCSTIMESET.RQL_H1)}}
									</div>
									<div>时</div>
									<div style="cursor: pointer;"
										@click="toIpt(parseInt(infoList.MCSTIMESET.RQL_M1),'分','RQL_M1','MCSTIMESET')">
										{{parseInt(infoList.MCSTIMESET.RQL_M1)}}
									</div>
									<div>分</div>
								</div>
							</div>
							<div class="month flex">
								<div class="month_words">月记录时间：</div>
								<div class="month_record flex">
									<div style="cursor: pointer;"
										@click="toIpt(parseInt(infoList.MCSTIMESET.YQL_D1),'月','YQL_D1','MCSTIMESET')">
										{{parseInt(infoList.MCSTIMESET.YQL_D1)}}
									</div>
									<div>日</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right_down">

					<div class="title">
						<div class="left_title">综合运行评价</div>
					</div>

					<div class="right_down_content flex align-cen justify-content-cen" style="height:12vh">
						<div class="right_down_text">
							{{infoList.MCSJNKH.LJZKLB >=90 ? 'A': infoList.MCSJNKH.LJZKLB <90 && infoList.MCSJNKH.LJZKLB >=70 ? 'B' : infoList.MCSJNKH.LJZKLB < 70 && infoList.MCSJNKH.LJZKLB>= 60 ? 'C' : 'D'}}
        
						</div>
						<div class="right_down_text">
							{{infoList.MCSJNKH.LJWDXB >=90 ? 'A': infoList.MCSJNKH.LJWDXB <90 && infoList.MCSJNKH.LJWDXB >=70 ? 'B' : infoList.MCSJNKH.LJWDXB < 70 && infoList.MCSJNKH.LJWDXB >= 60 ? 'C' : 'D'}}

						</div>
						<div class="right_down_text">
							{{infoList.MCSJNKH.LJRSXLB >=90 ? 'A': infoList.MCSJNKH.LJRSXLB <90 && infoList.MCSJNKH.LJRSXLB >=70 ? 'B' : infoList.MCSJNKH.LJRSXLB < 70 && infoList.MCSJNKH.LJRSXLB>= 60 ? 'C' : 'D'}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
		 <Historical v-if="isHshow" @sendStatus="isHshow = false" :historyname="historyname" :node="node"
			:Lkname="Lkname" :chName="chName" :infoList="infoList"></Historical>
		<Cfbcysz v-if="flag == 5 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose" :infoList="infoList">
		</Cfbcysz>
		<Cfbserve v-if="flag == 7 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></Cfbserve>
	</div>

</template>

<script>
	import Historical from "@/components/Historical";
	import inputVal from "@/components/inputVal" //输入框组件
	import Cfbcysz from "@/views/CfbBoiler/CfbCommonSetting/index.vue"; //常用设置组件
	import Cfbserve from "@/views/CfbBoiler/CfbOnlineService/index.vue"; //客服组件
	export default {
		name: "RqSecurity",
		components: {
			Historical,
			inputVal,
			Cfbcysz,
			Cfbserve
		},
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		data: () => {
			return {
				chName: '',
				flag: 2,
				value1: false,
				value2: true,
				isComShow: false,
				isHshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				projectData: {},
				isDisable: true,
				authInfo: '',
				UserInfo: '',
				spotArr: [],
				grouptime: null,
				publicMark: 'MCSJNKH',
				publicMark1: 'MCSZXJK',
				publicMark2: 'MCSZKL',
				list: [{
						name: '给水累计(t)',
						job: 'LJGSB',
						day: 'LJGSR',
						month: 'LJGSY',
						zdy: 'LJGSZ'
					}, {
						name: '蒸汽累计(t)',
						job: 'LJZQB',
						day: 'LJZQR',
						month: 'LJZQY',
						zdy: 'LJZQZ'
					},
					{
						name: '煤量(万吨)',
						job: 'LJMLB',
						day: 'LJMLR',
						month: 'LJMLY',
						zdy: 'LJMLZ'
					},
					{
						name: '吨汽耗(煤)',
						job: 'LJMHB',
						day: 'LJMHR',
						month: 'LJMHY',
						zdy: 'LJMHZ'
					},
					{
						name: '风机电耗(万度)',
						job: 'LJDHB',
						day: 'LJDHR',
						month: 'LJDHY',
						zdy: 'LJDHS'
					},
					{
						name: '自控率(%)',
						job: 'LJZKLB',
						day: 'LJZKLR',
						month: 'LJZKLY',
						zdy: 'LJZKLZ'
					},
					{
						name: '稳定性',
						job: 'LJWDXB',
						day: 'LJWDXR',
						month: 'LJWDXY',
						zdy: 'LJWDXZ'
					}
				],
				list1: [{
						name: '水位精度',
						mark: 'ZXJK_SWE',
						swich: 'ZXJK_EN101'
					},
					{
						name: '汽温精度',
						mark: 'ZXJK_QWE',
						swich: 'ZXJK_EN102'
					}, {
						name: '负压精度',
						mark: 'ZXJK_FYE',
						swich: 'ZXJK_EN103'
					}, {
						name: '料层差压',
						mark: 'ZXJK_LCE',
						swich: 'ZXJK_EN104'
					}, {
						name: '一次风量',
						mark: 'ZXJK_YCFE',
						swich: 'ZXJK_EN105'
					}, {
						name: '二次风量',
						mark: 'ZXJK_ECFE',
						swich: 'ZXJK_EN106'
					}, {
						name: '氧量精度',
						mark: 'ZXJK_YLE',
						swich: ''
					}, {
						name: '主汽压力',
						mark: 'ZXJK_QYE',
						swich: 'ZXJK_EN107'
					}, {
						name: '床温精度',
						mark: 'ZXJK_CWE',
						swich: 'ZXJK_EN108'
					},
				],
				list2: [{
						name: '减温自控率',
						mark: 'ZKLJW',
					},
					{
						name: '给水自控率',
						mark: 'ZKLSS'
					},
					{
						name: '给煤自控率',
						mark: 'ZKLGM1'
					},
					{
						name: '引风自控率',
						mark: 'ZKLYF'
					},
					{
						name: '一次风自控率',
						mark: 'ZKLYCF'
					},
					{
						name: '二次风自控率',
						mark: 'ZKLECF'
					},
					{
						name: '除渣自控率',
						mark: 'ZKLCZ'
					},
					{
						name: '返料自控率',
						mark: 'ZKLFL1'
					}
				]
			}
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n
				},
				deep: true // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.$bus.$on("sendMsg", (msg) => {
				// 兄弟组件发送来的消息
				this.flag = msg;
			});
		},
		methods: {
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {

						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			//    打开历史趋势
			toCompon(key, name, name2, name3, name4) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.isMshow = true
						case 1:
							return this.isFshow = true
						case 2:
							return this.isHshow = true, this.chName = name4

					}
				}, 300)
			},
			isClose(val, val1) {
				switch (val) {
					case 'CfbCommonSetting':
					case (this.flag == 5):
						return this.flag = 2, this.$bus.$emit("footersendMsg", 2)
					case 'Cfbserve':
					case (this.flag == 7):
						return this.flag = 2, this.$bus.$emit("footersendMsg", 2)
				}
			},
			isClose1() {
				this.isHshow = false
			},
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.fl-t {
		float: left;
	}

	.fr-t {
		float: right;
	}

	.title-hang {
		height: 5vh;
		width: 96vw;
		font-family: MicrosoftYaHei;
		font-size: 2vh;
		font-weight: normal;
		font-stretch: normal;
		line-height: 2vh;
		letter-spacing: 0vh;
	}

	.zhuangshizb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate06.png");
		background-size: 100% 100%;
		margin-left: 3.5vw;
		margin-top: 1.5vh;
	}

	.zhuangshizj {
		width: 26vw;
		height: 4vh;
		line-height: 4vh;
		margin-top: 1vh;
		text-align: center;
		background-image: url("~@/assets/images/rfl_evaluate05.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		font-family: MicrosoftYaHei;
		font-size: 3vh;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0vh;
		color: #0ef7ff;
	}

	.zhuangshiyb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate04.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		margin-top: 1.5vh;
	}

	.all {
		font-family: PingFang-SC-Regular;
		font-weight: normal;
		font-stretch: normal;
	}

	.title {
		width: 11vw;
		font-size: 2vh;
		line-height: 3vh;
		letter-spacing: 0vh;
		color: #ffffff;
		letter-spacing: 0vh;
		text-align: center;
	}

	.left {
		width: 33vw;
		height: 74vh;
		margin-left: 1.5vw;
		background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/left.png");
		background-size: 100% 100%;

		.left_content {
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vw;
			color: #00e4ff;
			text-align: center;

			.left_line0 {
				margin-left: 2vw;
				width: 8vw;

			}

			.left_line1 {
				margin-left: 2vw;
				width: 8vw;
				color: #8aeaff;
			}

			.left_line2 {
				width: 5vw;
				cursor: pointer;
			}

			.left_line3 {
				width: 5vw;
				cursor: pointer;
			}

			.left_line4 {
				width: 5vw;
				cursor: pointer;
			}

			.left_line5 {
				width: 6vw;
				cursor: pointer;
			}
		}
	}

	.middle {
		width: 28vw;
		margin-left: 1vw;

		.middle_up {
			height: 20vh;
			background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/middle1.png");
			background-size: 100% 100%;

			.status {
				margin-left: 0.5vw;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 4vh;
				letter-spacing: 0vw;

				.status_style {
					margin-top: 2vh;
					margin-left: 1vw;
					width: 7.5vw;
					height: 4vh;
					text-align: center;
				}

				.blue {
					border: 1px solid #0085eb;
					background: #0085eb40;
					box-sizing: border-box;
					color: #1bb1ff;
					cursor: pointer;
				}

				.green {
					border: 1px solid #00ff8a;
					border-radius: 0vh;
					color: #09bd51;
					cursor: pointer;
				}
			}

			.middle_up_times {
				line-height: 2.5vh;
				margin-left: 1.5vw;

				.box {
					width: 12vw;
					height: 5vh;
					text-align: center;
				}

				.up_box {
					margin-top: 2vh;
				}

				.box_words {
					color: #458ca4;
				}

				.box_time {
					color: #ffffff;
					cursor: pointer;
				}
			}
		}

		.middle_down {
			height: 52vh;
			background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/middle2.png");
			background-size: 100% 100%;
			margin-top: 2vh;

			.weight {
				font-family: PingFang-SC-Regular;
				font-size: 2vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 4vh;
				letter-spacing: 0vh;

				.weight_words {
					margin-left: 21vw;
					color: #458ca4;
				}

				.weight_point {
					margin-top: 1.5vh;
					margin-left: 0.5vw;
					width: 3vw;
					height: 0.8vh;
					background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/points.png");
					background-size: 100% 100%;
				}
			}

			.precision {
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vw;
				letter-spacing: 0vw;

				.precision_line {
					flex-wrap: wrap;

					.precision_box {
						width: 10vw;
						height: 8vh;
						background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/stable_bg.png");
						background-size: 100% 100%;
						margin-left: 2.8vw;
						margin-top: 1vh;
						text-align: right;
						position: relative;

						.precision_num {
							cursor: pointer;
							width: 9vw;
							color: #00ffb4;
							line-height: 4vh;
						}

						.precision_name {
							width: 9vw;
							color: #8aeaff;
							line-height: 4vh;
							font-size: 1vw;

						}

						.switchdiv {
							cursor: pointer;
							position: absolute;
							top: 0vh;
							left: 1vw;
							z-index: 99;

							.el-switch {
								position: relative;
								z-index: -1 !important;
							}

							.el-switch.is-disabled {
								opacity: 1 !important;
							}
						}
					}
				}
			}
		}
	}

	.right {
		width: 33vw;
		margin-left: 1vw;

		.right_up {
			height: 36vh;
			background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/right1.png");
			background-size: 100% 100%;

			.right_up_content {
				.content_left {

					.content_left_line {
						font-family: PingFang-SC-Regular;
						font-weight: normal;
						font-stretch: normal;
						letter-spacing: 0vh;
						text-align: center;
						line-height: 4vh;

						.words {
							margin-left: 2vw;
							font-size: 1vw;
							width: 8vw;
							color: #8aeaff;
						}

						.num {
							margin-left: 2vw;
							width: 2vw;
							color: #00e4ff;
							cursor: pointer;
						}

						.statistical {
							margin-left: 2vw;
							width: 5vw;
							color: #1082a8;
						}
					}
				}

				.content_right {
					margin-left: auto;
					padding-right: 4vw;

					.el-progress {
						margin-left: 2vw;
						margin-top: 2vh;
						background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/stable_content.png");
						background-size: 100% 100%;
					}

					.control_rate {
						width: 10vw;
						height: 9vh;
						background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/stable_total.png");
						background-size: 100% 100%;
						margin-left: 1vw;
						font-family: PingFang-SC-Regular;
						font-weight: normal;
						font-stretch: normal;
						line-height: 2vw;
						letter-spacing: 0vw;
						text-align: center;

						.control_rate_num {
							font-size: 2vw;
							line-height: 3vw;
							color: #00e4ff;
							cursor: pointer;
						}

						.control_rate_words {
							font-size: 1vw;
							color: #8aeaff;
						}
					}
				}
			}

		}

		.right_middle {
			height: 17vh;
			background-image: url("~@/assets/images/RqBoiler/bg_right_down.png");
			background-size: 100% 100%;
			margin-top: 2vh;

			.right_middle_content {
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vw;

				.nail {
					letter-spacing: 0.3vw;

					.nail_line {
						margin-top: 1vh;
						text-align: center;

						.nail_word {
							// width: 10vw;
							color: #8aeaff;
						}

						.nail_time {
							// width: 10vw;
							color: #00e4ff;

							.first_column {
								margin-left: 2vw;
							}
						}
					}
				}

				.time_record {
					margin-top: 1vh;
					margin-left: 2vw;
					font-family: PingFang-SC-Regular;
					font-size: 1vw;
					font-weight: normal;
					font-stretch: normal;
					line-height: 1vw;

					.day {
						.day_words {
							letter-spacing: 0vw;
							color: #458ca4;
						}

						.day_record {
							letter-spacing: 0.5vw;
							color: #00e4ff;
						}
					}

					.month {
						margin-left: 5vw;

						.month_words {
							letter-spacing: 0vw;
							color: #458ca4;
						}

						.month_record {
							letter-spacing: 0.5vw;
							color: #00e4ff;
						}
					}
				}
			}
		}

		.right_down {
			height: 17vh;
			background-image: url("~@/assets/images/RqBoiler/bg_right_down.png");
			background-size: 100% 100%;
			margin-top: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vh;
			letter-spacing: 0vh;

			.right_down_text {
				color: #8aeaff;
				font-size: 2vw;
				width: 2vw;
				height: 4vh;
				line-height: 4vh;
				background: #01a4bf80;
				border: solid 1px #185378;
				text-align: center;
				margin-right: .5vw;
			}

			.right_down_left {
				.light {
					width: 3vw;
					height: 6vh;
					background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/statistics.png");
					background-size: 100% 100%;
					margin-top: 4vh;
					margin-left: 3vw;
				}

				.per_ton {
					margin-top: 4vh;
					margin-left: 2vw;

					.per_ton_num {
						color: #00e4ff;
					}

					.per_ton_words {
						color: #8aeaff;
					}
				}
			}

			.right_down_right {
				width: 10vw;
				margin-left: 6vw;
				margin-top: 2vh;

				.com_evaluation {
					text-align: center;
					color: #8aeaff;
				}

				.stars {
					text-align: center;
					font-family: PingFang-SC-Regular;
					font-size: 2vw;
					font-weight: normal;
					font-stretch: normal;
					line-height: 3vw;
					letter-spacing: 0vw;
					color: #00e4ff;
					// width: 8vw;
					// height: 3vh;
					// background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/star1.png");
				}
			}
		}
	}
</style>
